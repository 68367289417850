// This file is automatically generated by `generated_js` task. Any changes will be lost
/* tslint:disable */
export const GoCDVersion = {
  "version": "20.9.0",
  "buildNumber": "12335",
  "gitSha": "cccb4960bde1166ccd79277093295ed5a18573ed",
  "fullVersion": "20.9.0-12335",
  "formattedVersion": "20.9.0 (12335-cccb4960bde1166ccd79277093295ed5a18573ed)",
  "copyrightYear": "2020"
}


function stripLeadingPrefix(suffix: string, prefix: string) {
  if (suffix.startsWith(prefix)) {
      suffix = suffix.substring(1);
  }
  return suffix;
}

export function docsUrl(suffix: string = '') {
  return `https://docs.gocd.org/20.9.0/${stripLeadingPrefix(suffix, '/')}`
}

export function apiDocsUrl(suffix: string = '') {
  return `https://api.gocd.org/20.9.0/${stripLeadingPrefix(suffix, '#')}`
}
    
